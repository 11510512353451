<script setup lang="ts">
import { formatTestName, formatVariantName } from "~/utils/text";
import { enabledAbTests, testConfig } from "~/app/ab-tests.config";
import AppIcon from "~/components/app/AppIcon.vue";
import { useDefaultAbTest } from "~/composables/useAbTest";

const runtimeConfig = useRuntimeConfig();
const isActive = ["dev", "staging"].includes(runtimeConfig.public.appEnv);

const dayjs = useDayjs();
const { setLocale, locales, locale: currentLocale } = useNuxtApp().$i18n;

const showABTestSwitcher = ref(false);

const abTests = Object.entries(testConfig)
  .map(([name, test]) => ({ name, ...test }))
  .filter((test) => enabledAbTests.includes(test.name));

const { hasFreeTrial, hasSkippedFunnel } = useConditions();
const { userType } = storeToRefs(useUserStore());

const conditions = computed(() => [
  {
    name: "Login status",
    type: "buttons",
    value: userType.value,
    options: [
      { name: "Guest", value: "guest" },
      { name: "User", value: "user" },
      { name: "Token user", value: "tokenUser" },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: async (value: any): Promise<void> => {
      if (value === "guest") {
        useTokenUserStore().$reset();
        useUserStore().user = undefined;
        useAuthStore().$reset();
      }

      if (value === "user") {
        await useAuthStore().login("yz-maintain-31-DE-c14e580a3bfe5e3aeeac@byom.de", "yztest");
        await useUserStore().fetchUser();
        useTokenUserStore().$reset();
      }

      if (value === "tokenUser") {
        useTokenUserStore().token = "c4aecd1fccd58e31618f00fda3df0e72bc6c38754c6533e72d1c82b0126414d5";
        await useTokenUserStore().fetchTokenUser();
        useUserStore().user = undefined;
        useAuthStore().$reset();
      }
    },
  },
  {
    name: "Free trial active",
    type: "buttons",
    value: hasFreeTrial.value,
    options: [
      { name: "Yes", value: true },
      { name: "No", value: false },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: (value: any): void => {
      useCheckoutStore().checkoutParams.trial_days = value ? 7 : 0;
    },
  },
  {
    name: "Discount code",
    type: "select",
    value: useDiscountStore().code,
    options: [
      { name: "Dominique", value: "domi-west" },
      { name: "Name only", value: "name-only" },
      { name: "HelloFresh", value: "hf-wzu9p" },
      { name: "Without partner content", value: "no-content" },
      { name: "None", value: "" },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: (value: any): void => {
      useDiscountStore().code = value;
    },
  },
  {
    name: "Has skipped funnel",
    type: "buttons",
    value: hasSkippedFunnel.value,
    options: [
      { name: "Yes", value: true },
      { name: "No", value: false },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: (value: any): void => {
      useOnboardingStore().registrationParams.incomplete = value;
    },
  },
  {
    name: "Goal",
    type: "buttons",
    value: useOnboardingStore().registrationParams.goal,
    options: [
      { name: "Lose", value: "lose" },
      { name: "Gain", value: "gain" },
      { name: "Maintain", value: "maintain" },
      { name: "None", value: undefined },
    ],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    change: (value: any): void => {
      useOnboardingStore().registrationParams.goal = value;
      const user = useUserStore().user;
      if (user) {
        user.goal = value;
      }
    },
  },
]);

async function changeLanguage(payload: Event) {
  const locale = (payload.target as HTMLSelectElement).value;

  dayjs.locale(locale);
  await setLocale(locale);
}
</script>

<template>
  <div v-if="isActive" class="tw-fixed tw-bottom-5 tw-right-5 tw-z-50">
    <div
      v-if="showABTestSwitcher"
      class="tw-absolute tw-bottom-full tw-right-0 tw-z-10 tw-flex tw--translate-y-3 tw-flex-col tw-gap-3 tw-rounded-lg tw-bg-white tw-p-3 tw-shadow-lg"
    >
      <div>
        <h4>Language</h4>
        <select class="tw-w-full" @change="changeLanguage">
          <option
            v-for="locale in locales"
            :key="locale.code"
            :value="locale.code"
            :selected="locale.code === currentLocale"
          >
            {{ locale.name }}
          </option>
        </select>
      </div>
      <hr class="tw-m-0" />
      <div>
        <h4>Current User</h4>
        <div class="tw-flex tw-flex-col tw-gap-3">
          <div v-for="condition in conditions" :key="condition.name" class="tw-flex tw-flex-col">
            <div class="tw-text-sm">{{ condition.name }}</div>
            <select
              v-if="condition.type === 'select'"
              class="tw-w-full"
              :value="condition.value"
              @change="condition.change(($event.target as HTMLSelectElement).value ?? '')"
            >
              <option v-for="(option, key) in condition.options" :key="key" :value="option.value">
                {{ option.name }}
              </option>
            </select>

            <div v-else class="tw-flex tw-items-stretch tw-justify-items-center">
              <div
                v-for="(option, index) in condition.options"
                :key="option.name"
                type="button"
                class="tw-flex-grow tw-text-nowrap tw-px-4 tw-py-2 tw-text-center tw-text-sm tw-shadow-sm"
                :class="{
                  'tw-bg-yz-blue-400 tw-text-white': condition.value === option.value,
                  'tw-rounded-s-lg': index === 0,
                  'tw-rounded-e-lg': index === condition.options.length - 1,
                }"
                @click="condition.change(option.value)"
              >
                {{ option.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="tw-m-0" />
      <div>
        <h4>A/B Tests</h4>
        <div class="tw-flex tw-flex-col tw-gap-3">
          <div v-for="test in abTests" :key="test.name" class="tw-flex tw-flex-col tw-gap-1">
            <div class="tw-text-nowrap tw-text-sm">{{ formatTestName(test.name) }}</div>
            <div>
              <div class="tw-flex" role="group">
                <div
                  v-for="(variant, index) in test.variants"
                  :key="variant.variant"
                  type="button"
                  class="tw-flex-grow tw-px-4 tw-py-2 tw-text-center tw-text-sm tw-shadow-sm"
                  :class="{
                    'tw-bg-yz-blue-400 tw-text-white':
                      variant.variant === useDefaultAbTest().userGetAbTestGroup(test.name as keyof typeof testConfig),
                    'tw-rounded-s-lg': index === 0,
                    'tw-rounded-e-lg': index === test.variants.length - 1,
                  }"
                  @click="useDefaultAbTest().userSetAbTestGroup(test.name as keyof typeof testConfig, variant.variant)"
                >
                  {{ formatVariantName(variant.variant, test.name) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tw-cursor-pointer tw-leading-none" @click="showABTestSwitcher = !showABTestSwitcher">
      <app-icon icon="teenyicons:ab-testing-solid" class="tw-align-bottom" width="50px"></app-icon>
    </div>
  </div>
</template>
